<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">题库管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel">题库名称:</span>
              <el-input v-model="bankName" type="text" size="small" clearable placeholder="请输入题库名称" />
            </div>
            <el-button style="margin-left: 20px" class="bgc-bv" round @click="getData()">查询</el-button>
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="100px" :index="indexMethod" />

              <el-table-column label="题库名称" align="center" prop="bankName" show-overflow-tooltip min-width="100" />
              <el-table-column label="试题数量" align="center" prop="questionNum" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">
                  {{ scope.row.questionNum||'0' }}
                </template>
              </el-table-column>
              <el-table-column label="更新时间" align="center" prop="updateTime" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">
                  {{ scope.row.updateTime||scope.row.createTime|| moment }}
                </template>
              </el-table-column>

              <el-table-column label="操作" align="center" width="260" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="handleCreate('edit', scope.row)">编辑
                  </el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="goQuestionList(scope.row)">题目管理
                  </el-button>
                  <el-button size="mini" style="padding: 0px 5px" type="text"
                    @click="certificateRelease(scope.row.bankId, scope.row.sellState)">{{ scope.row.sellState ? '下架' :
                      '上架' }}</el-button>
                  <el-tooltip class="item" effect="dark" content="题库下载暂不支持下载组合题类型试题" placement="top">
                    <el-button el-button type="text" style="padding: 0px 5px" size="mini" @click="download(scope.row.bankId)">下载</el-button>
                  </el-tooltip>
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="deleteListData(scope.row)">删除
                  </el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="60%">
      <span style="width: 100%;">
        <div class="ovy-a">
            <div class="form-box">
            <el-form ref="ruleForm" label-width="7.5rem" class="form" :rules="rules" :model="ruleForm">
                <el-form-item label="题库名称：" prop="bankName">
                <el-input v-model="ruleForm.bankName" size="small" maxlength="20" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="知识点：" prop="knowledgeId">
                  <el-select v-model="ruleForm.knowledgeId" placeholder="请选择">
                    <el-option
                        v-for="item in knowledgeRoot"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddSave()">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "QuestionBankManage",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      stu:'',
      bankName: "", //专题名称

      dialogVisible: false,
      dialogTitle: '',
      ruleForm: {
        bankId:'',
        bankName:'',
        knowledgeId:'',
      },
      knowledgeRoot: [],
      rules: {
        bankName: [
          { required: true, message: "请输入题库名称", trigger: "blur" },
        ],
        knowledgeId: [
          { required: true, message: "请选择知识点", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getTableHeight();
  },
  methods: {
    getKnowledgeRootList() {
      this.$post("/biz/exam/bank/knowledge/root/list", {},3000,true,6)
          .then((ret) => {
            if (ret.status == 0) {
              this.knowledgeRoot = [];
              for (const item in ret.data) {
                this.knowledgeRoot.push({
                  value: ret.data[item].knowledgeId,
                  label: ret.data[item].knowledgeName,
                });
              }
            }
          })
          .catch((err) => {
            return;
          });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        bankName: this.bankName,
      };
      this.doFetch(
          {
            url: "/biz/exam/bank/pageList",
            params,
            pageNum,
          },true,6
      );
    },
    // 新增
    handleCreate(stu, row) {
      this.getKnowledgeRootList();
      this.stu = stu
      // stu add 添加  edit  编辑
      this.ruleForm = {

      }
      if(stu == 'add'){
        this.dialogTitle = '新建题库'
        this.$set(this.ruleForm, 'bankId', '')
        this.$set(this.ruleForm, 'bankName', '')
        this.$set(this.ruleForm, 'knowledgeId', '')
      }
      if(stu == 'edit'){
        this.dialogTitle = '修改题库'
        this.$set(this.ruleForm, 'bankId', row.bankId)
        this.$set(this.ruleForm, 'bankName', row.bankName)
        this.$set(this.ruleForm, 'knowledgeId', row.knowledgeId)
      }

      this.dialogVisible = true
    },
    // 添加编辑
    doAddSave(){
      const ruleForm = this.$refs.ruleForm;
      ruleForm.validate((valid) => {
        if (valid) {
          if(this.stu == 'add'){
            this.$post("/biz/exam/bank/insert", {...this.ruleForm},3000,true,6).then((res) => {

              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: "添加成功",
                });
                this.dialogVisible = false
                this.getData(-1)
              }
            });
          }
          if(this.stu == 'edit'){
            this.$post("/biz/exam/bank/modify", {...this.ruleForm},3000,true,6).then((res) => {

              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: "修改成功",
                });
                this.dialogVisible = false
                this.getData(-1)
              }
            });
          }

        }
      });
    },
    // 跳转题目列表
    goQuestionList(row){
      this.$router.push({
        path: "/inkQuestionBank/QuestionBankManage/QuestionList",
        query: {
          bankId: row.bankId,
          bankName: row.bankName
        }
      });
    },
    //上架/下架
    certificateRelease(bankId,sellState) {
      this.$post("/biz/exam/bank/modifySellState", { bankId, sellState: !sellState }, 3000, true, 6).then((ret) => {
        if (ret.status == "0") {
          this.$message.success(sellState?"下架成功！":"上架成功！");
          this.getData(-1);
        }
      });
    },
    // 删除
    deleteListData(row) {
      this.$post("/biz/exam/bank/getValidConfig", { bankId: row.bankId }, 3000, true, 6).then((res) => {
        this.$confirm(
          res.data ? "有学员在题库刷题，删除后，题库将自动失效且不可恢复，是否继续删除？" : '题库删除后不可恢复，是否继续删除？',
          "提示",
          {
            confirmButtonText: "是",
            cancelButtonText: "否",
            type: "warning",
          }
        )
          .then(() => {
            this.$post("/biz/exam/bank/delete", { bankId: row.bankId }, 3000, true, 6).then((ret) => {
              if (ret.status == "0") {
                this.$message.success("操作成功");
                this.getData(this.pageNum);
              }
            });
          }).catch((

          ) => {
            return;
          });
      });
    },
    // 下载
    download(bankId){
      this.$post("/biz/exam/bank/export",{bankId},3000,true,6).then((res) => {
        const arr=[...res.data];
        for (let item of arr) {
          if (!this.downloadItems.includes(item.taskId)) {
            this.$store.dispatch("pushDownloadItems", item.taskId);
          } else {
            this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
            );
          }
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // console.log(val.query)
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;

  .el-switch__core {
    width: 40px !important;
  }

  .el-switch__core:after {
    background-color: #fff;
  }

  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
</style>